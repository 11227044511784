import React, { useEffect, useState } from 'react'
import avatar from '../../Assets/Images/img_avatar.png'
import deleteIcon from '../../Assets/Images/icon-delet.png'
import Footer from '../../Components/Footer/Footer';
import Cookies from 'js-cookie';
import { base_url } from '../../data';
import { Link, useNavigate } from 'react-router-dom';
const AllPropertyList = () => {
const [enquiry, setEnquiry] = useState([])
const userEmail = Cookies.get('userEmail');
const navigate = useNavigate();

  const getEnquiry = async ()=>{
    try {
      const response = await fetch(`${base_url}/property/by-agent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ agentEmail: userEmail }) 
      });
      
      if (!response.ok) {
        throw new Error('Property not found');
      }
      const data = await response.json();
      console.log(data);
      setEnquiry(data);
    } catch (error) {
      console.log(error.message);
    }
  }

const deleteEnquiry = async(propertyid)=>{
  try {
    const response = await fetch(`${base_url}/property/delete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ propertyid: propertyid }) 
    });
    
    if (!response.ok) {
      throw new Error('Property not found');
    }
    const data = await response.json();
    getEnquiry()
    console.log( data);
  } catch (error) {
    console.log(error.message);
  }
}




const ned = [
  {
    hello: "ok"
  },
  {
    hello: "ok"
  },
  {
    hello: "ok"
  },
  {
    hello: "ok"
  },
]
const goTO = (propertyid)=>{
navigate(`/update-property/${propertyid}`)
}
useEffect(()=>{
  getEnquiry()
},[])

    return (
        <div>
           <div className="postproperty-right" >
                <div className="box">
                  <div className="customer-enuiry">
                    <div className="customer-enuiry-in">
                      <div className="sub-title-02">All Property List</div>
                   {  
                    enquiry?.map((e)=>{
                      return <div  className='property-list' style={{display:'flex',padding:'10px'}}>
<Link style={{flex:'1', color:'#000'}} to={`/property-detail/${e.propertyid}`}><h4 style={{ color:'#000'}}> {e.projectTitleData.projectTitle}</h4></Link>
<div className="action-btn"style={{display:'flex',}} >
  <button onClick={()=>goTO(e.propertyid)} style={{background:'green',color:'#fff',outline:'none',border:'0',padding:'10px'}}>Update</button>
  <button onClick={()=>deleteEnquiry(e.propertyid)} style={{background:'red',color:'#fff',outline:'none',border:'0',zIndex:'99',padding:'10px'}}>Delete</button>
</div>
                      </div>
                    
                  
                    })
                   }
                    </div>
                  </div>
                </div>
              </div>
        </div>
      );
}

export default AllPropertyList