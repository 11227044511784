import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Admin.css'
import { base_url } from '../../data';
import { Link, useNavigate } from 'react-router-dom';
import deleteIcon from '../../Assets/Images/icon-delet.png'
const EnquiryAdmin = () => {
    const [tabs,setTabs] = useState('all')
    const [property, setProperty] = useState([]);
    const navigate = useNavigate()
    const getProperty = async () => {
    
      try {
        const response = await axios.get(`${base_url}/enquiry/all`);
        setProperty(response.data); 
        console.log("Enquiry", property)
      } catch (err) {
        console.log(err.message); 
      }
    };

    useEffect(() => {
    
    
        getProperty();
      }, [tabs]);

      
  return (
    <div className='admin'>
            <h3>Admin Panel</h3> 
        <div className="nav">
            <div onClick={()=>setTabs('all')} className={`tabs ${tabs==='all'?'active':''}`}>
               All Enquiry
            </div>
        </div>
        <div className="tab-layout">
{
    property.map((e,i)=>{
      
const deleteEnquiry = async(id)=>{
  try {
    const response = await fetch(`${base_url}/enquiry/delete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ enquiryId: id }) 
    });
    
    if (!response.ok) {
      throw new Error('Property not found');
    }
    const data = await response.json();
    getProperty()
    console.log( data);
  } catch (error) {
    console.log(error.message);
  }
}


      return <table key={i}>
      <tbody>
        <tr className="tr">
          <td className="td01">Property ID</td>
          <td className="td02">:</td>
          <td className="td01">{e.propertyId}</td>
        </tr>
        <tr className="tr">
          <td className="td01">Date</td>
          <td className="td02">:</td>
          <td className="td01">{e.date}</td>
        </tr>
        <tr className="tr">
          <td className="td01">Name</td>
          <td className="td02">:</td>
          <td className="td01">{e.name}</td>
        </tr>
        <tr>
          <td>Phone Number</td>
          <td>:</td>
          <td>{e.phone}</td>
        </tr>
        <tr>
          <td>Required</td>
          <td>:</td>
          <td>{e.required}</td>
        </tr>
        <tr className="tr">
          <td className="td01">
            Note
            <br />
            {/* <input type="text" placeholder="Agent Column | Enter Customer Feedback" /> */}
          </td>
          <td className="td02">:</td>
          <td className="td01">{e.note}</td>
        </tr>
        <tr className="tr">
          <td className="td01"></td>
          <td className="td02"></td>
          <td className="td01">
            <img onClick={()=>deleteEnquiry(e.enquiryId)} src={deleteIcon} alt="Delete" />
          </td>
        </tr>
      </tbody>
    </table>
    })
}
        </div>
    </div>
  )
}

export default EnquiryAdmin