import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Admin.css'
import { base_url } from '../../data';
import { Link, useNavigate } from 'react-router-dom';
const Admin = () => {
    const [tabs,setTabs] = useState('review')
    const [property, setProperty] = useState([]);
    const navigate = useNavigate()
    const getProperty = async () => {
        const url = tabs==='all'?`${base_url}/property/allowed`:`${base_url}/property/not-allowed`
      try {
        const response = await axios.get(url);
        setProperty(response.data); 
      } catch (err) {
        console.log(err.message); 
      }
    };

    const updateProperty = async (propertyid)=>{
        try {
            const response =  await fetch(`${base_url}/property/update-property-admin`,{
                method:'POST',
                headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    propertyid: propertyid
                  }),
              })
              if(response.status === 200){
                
              console.log('Property Updated successfully');
              getProperty();
              }
          } catch (err) {
            console.log(err.message); 
          }
    }

 const deleteProperty = async (propertyid) => {
    try {
     const response =  await fetch(`${base_url}/property/delete-property-admin`,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            propertyid: propertyid
          }),
      })
      if(response.status === 200){
        
      console.log('Property deleted successfully');
      getProperty();
      }
    } catch (error) {
      console.error('Error deleting property:', error);
    }
  };
    useEffect(() => {
    
    
        getProperty();
      }, [tabs]);
  return (
    <div className='admin'>
            <h3>Admin Panel</h3> <button className='tabs active' onClick={()=>{navigate('/admin/enquiry')}}>See All Enquiry</button>
        <div className="nav">
            <div onClick={()=>setTabs('review')} className={`tabs ${tabs==='review'?'active':''}`}>
                Under Review Properties
            </div>
            <div onClick={()=>setTabs('all')} className={`tabs ${tabs==='all'?'active':''}`}>
                All Properties
            </div>
        </div>
        <div className="tab-layout">
{
    property.map((e)=>{
        return <div  className="property-list-item">
           <img src={e.propertyImage[0]} alt="" />
           <Link to={`/property-detail/${e.propertyid}`}> <p>{e.projectTitleData.projectTitle}</p>
           </Link>
          
           <div className="actions">
           <button onClick={()=>deleteProperty(e.propertyid)} className='delete btn'>Delete</button>
           <button onClick={()=>updateProperty(e.propertyid)} className={`update btn ${tabs==='all'?'hidden':''}`}>{tabs==='all'?'Remove':'Allow'}</button>
           </div>
        </div>
    })
}
        </div>
    </div>
  )
}

export default Admin