import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { base_url } from '../../data';
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput';

const SearchAllPages = () => {
  const navigate = useNavigate();
  const [projectType, setProjectType] = useState('');
  const [location, setLocation] = useState('all');
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${base_url}/property/district-city`);
        const sortedData = response.data.sort((a, b) => b.propertyCount - a.propertyCount);
        setData(sortedData);
      } catch (err) {
        console.error('Error fetching data:', err.message);
      }
    };

    fetchData();
  }, []);

  const handleProjectTypeChange = (e) => {
    setProjectType(e.target.value);
  };

  const handleLocationChange = (value) => {
    setLocation(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/list?propertyType=${projectType}&districtCity=${location}`);
  };

  return (
    <div className="search-allpages">
      <div className="search-allpages-in">
        <form onSubmit={handleSubmit}>
          <div className="search-allpages-tr03">
            <div className="category">
              <select value={projectType} onChange={handleProjectTypeChange}>
              <option value="">Select Project Type</option>
              <option value="all">All Project </option>
              <option value="Flat">Flat / Apartments </option>
                <option value="Villa Flats">Villa Flats</option>
                <option value="Open Plots">Open Plots</option>
                <option value="Farm Lands">Farm Lands</option>
                <option value="Rent">Rent / Lease </option>
              </select>
            </div>
            <div className="location">
              <AutoCompleteInput 
                setLocation={setLocation}
                className="select"
                suggestions={data.map((e) => e.districtCity)}
                value={location==="all"?"":location}
                onChange={handleLocationChange}
              />
            </div>
            <div className="search">
              <button className="search-button" type="submit" style={{ width: '100%' }}>
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SearchAllPages;
